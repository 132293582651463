<template>
  <div>
    <headerCode />
    <div class="menu-info-box">
      <div class="menu-info">
        <span v-for="(it, index) in pageNav" :key="'pagenav' + index">
          {{ it.name }} <template v-if="index < pageNav.length - 1">></template>
        </span>
      </div>
    </div>
    <!-- 基础信息 -- 目前只有2部分，所以可以放这里，后期有改动  -->
    <userInfoCom />

    <router-view />
  </div>
</template>

<script>
import userInfoCom from "@/views/mySelf/components/userInfo.vue";
import headerCode from "@/views/mySelf/components/header.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    headerCode,
    userInfoCom,
  },
  computed: {
    ...mapGetters(["pageNav"]),
  },
  created(){
	const query = this.$route.query;
	if(query.code && query.bind == "wx") {
		this.$axios.postJSON('/base/user/bind/weixin', {
			code: query.code
		}).then((res) => {
			this.$route.push('/mySelf/account')
		})
	}
  },
};
</script>

<style lang="scss" scoped>
.menu-info-box {
  position: relative;
  height: 2px;
  width: 1200px;
  margin: 0 auto 0;
  .menu-info {
    color: #999999;
    position: absolute;
    top: 9px;
    font-size: 14px;
    span:last-child {
      color: #454545;
      font-weight: 500;
    }
  }
}
</style>
<style>
.framework {
  width: 593px;
}
</style>
