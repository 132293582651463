<template>
  <div id="header">
    <div class="bottom">
      <div class="content">
        <div class="nav">
          <div class="logo">
            <img @click="goPage" :src="platInfo.logo_url" />
          </div>
          <div class="box">
            <div :class="['list', $store.state.choseType == 1 ? 'isChose' : '']" @click="goHome">
              <span>首页</span>
            </div>
            <!-- <div
              :class="['list', type == 2 ? 'isChose' : '']"
              @click="$router.push('/mySelf/mesCenter?type=2')"
            >
              <span>即时通信</span>
            </div> -->
            <div
              :class="['list', $store.state.choseType == 3 ? 'isChose' : '']"
              @click="accountBtn"
            >
              <span>账号管理</span>
            </div>
            <!-- <div class="list">
              <span>账户管理</span>
            </div>
            <div class="list">
              <span>我的收藏</span>
            </div>
            <div class="list">
              <span>我的浏览</span>
            </div> -->
            <div :class="['list', $store.state.choseType == 4 ? 'isChose' : '']" @click="sucaiBtn">
              <span>我的素材</span>
            </div>
            <div :class="['list', $store.state.choseType == 5 ? 'isChose' : '']" @click="myOrderBtn">
              <span>我的采购</span>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="list">
            <img @click="tuichuLogin" title="退出登录" :src="user_avatar" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const URLS = {
  IDENTITY: "/api/staff/identity",
};
export default {
  data() {
    return {
      array: [],
      type: 1,
      platInfo: {},
      user_avatar: "",
    };
  },
  created() {
    if (localStorage.platInfo) {
      this.platInfo = JSON.parse(localStorage.platInfo);
    }
    if (sessionStorage.getItem("AccessToken")) {
      this.getUserInfo();
    }
  },
  methods: {
    goPage(){
      this.$axios.get("/setting/public/logo_link").then(res=>{
        if(res.code == 200){
          let data = res.data;
          if(data && data.url){
            window.open(data.url);
          }
        }
      })
    },
    goHome() {
      this.$store.commit("updatePageNav", []);
      this.$router.push("/mySelf/index");
    },
    accountBtn() {
      this.$store.commit("updatePageNav", []);
      this.$router.push("/mySelf/account");
    },
    sucaiBtn() {
      this.$store.commit("updatePageNav", []);
      this.$router.push("/sucai");
    },
    myOrderBtn(){
      this.$store.commit("updatePageNav", []);
      this.$router.push("/shoppingCar");  
    },
    tuichuLogin() {
      this.$confirm("确定要退出登录吗？", "提示").then(() => {
        if (process.env.NODE_ENV === "production") {
          sessionStorage.removeItem("AccessToken");
         
        } else {
          localStorage.removeItem("AccessToken");
        }
        this.$router.push("/login");
      });
    },
    getUserInfo() {
      this.$axios.get("/base/user/login_info").then(res => {
        if (res.code == 200) {
          let data = res.data;
          this.user_avatar = `https://avatar.${VUE_APP_API_URL}/user/avatar/${data.user_id}`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  width: 100%;
  > .top {
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(51, 51, 51, 100);

    .box {
      display: flex;
      align-items: center;

      > .list {
        line-height: 20px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        > .img {
          margin-right: 5px;
          width: 20px;
        }
        > .more {
          margin-left: 5px;
          width: 12px;
        }
        .el-dropdown-link {
          color: #333;
        }
        > ul {
          position: absolute;
          top: 20px;
          left: 0;
          width: 100%;
          display: none;
          border: 1px solid rgba(204, 204, 204, 100);
          background-color: #fff;
          > li {
            line-height: 20px;
            text-align: center;
          }
        }
      }
      > .list:hover {
        > ul {
          display: block;
        }
      }
    }

    .right {
      > .list {
        border-right: 1px solid rgba(204, 204, 204, 100);
        cursor: pointer;
      }

      > .list:last-child {
        border: none;
      }
    }
  }

  > .bottom {
    height: 64px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #35373a;
    .nav {
      display: flex;
      align-items: center;
      > .logo {
        display: flex;
        justify-content: flex-start;
        width: 114px;
        > img {
          height: 32px;
          width: auto;
          max-width: 94px;
          cursor: pointer;
        }

        > .title {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          > p {
            font-size: 18px;
            letter-spacing: 1px;
            line-height: 18px;
            margin-top: 4px;
          }

          > span {
            height: 18px;
            width: 70px;
            text-align: center;
            line-height: 18px;
            border-radius: 50px;
            border: 1px solid rgba(255, 255, 255, 100);
            font-size: 12px;
            cursor: pointer;
            margin-top: 10px;
          }
        }
      }
      > .box {
        display: flex;
        align-items: center;
        > .list {
          // padding: 0 22px;
          width: 100px;
          text-align: center;
          height: 64px;
          line-height: 64px;
          cursor: pointer;
          &:hover {
            color: #4387f8;
          }
          &:hover span {
            border-bottom: 2px solid #4387f8;
            padding-bottom: 5px;
            box-sizing: border-box;
          }
        }
      }
    }
    .center {
      display: flex;
      align-items: center;
      > .list {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
        cursor: pointer;
        position: relative;
        > img {
          width: 20px;
          height: 20px;
        }
        > b {
          width: 1px;
          height: 25px;
          background-color: #fff;
          margin-left: 15px;
        }
        > i {
          width: 5px;
          height: 5px;
          position: absolute;
          background-color: #4387f8;
          border-radius: 50%;
          top: 0;
          right: 5px;
        }
      }
      > .list:last-child {
        margin-left: 15px;
        > img {
          width: 34px;
          height: 34px;
          margin-right: 5px;
        }
      }
    }
  }

  .content {
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.isChose {
  color: #4387f8;
  span {
    border-bottom: 2px solid #4387f8;
    padding-bottom: 5px;
    box-sizing: border-box;
  }
}
</style>
